.custom-switch.p-inputswitch {
    width: 32px;
    height: 16px;
}
.custom-switch .p-inputswitch-slider:before {
    width: 12px;
    height: 12px;
    left: 3px;
    margin-top: -6px;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    transform: translateX(14px);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: none;
}

.lms-drodown .p-dropdown-label {
    padding: 14px 10px;
    line-height: 22px;
}

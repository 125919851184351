.bg-spotlilac {
    border-radius: 10px;
    background: var(--Spot-Lilac, #BF4BFF);
}

.bg-white-00 {
    border-radius: 4px;
    background: var(--White-00, #FFF);
}

.shadow-modal-drop {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.text-gray-00 {
    color: #F8F9FA
}

.text-white-00 {
    color: #FFFFFF
}

.text-gray-10 {
    color: #000000
}
.text-purple-05 {
    color: #6032DB
}
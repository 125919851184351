.bg-purple-00 {
  background: var(--Purple-00, #efeafb);
}

.bg-purple-05 {
  background: var(--Purple-05, #6032db);
}

.text-purple-05 {
  color: #6032db;
}

.border-purple-05 {
  border-radius: 4px;
  border: 1px solid var(--Purple-05, #6032db);
}

.border-purple-00 {
  border-radius: 4px;
  border: 1px solid var(--Purple-05, #6032db);
  background: var(--White-00, #fff);
}

.bg-gray-00 {
  background-color: #f8f9fa;
}
.text-gray-10 {
  color: #000000;
}
.text-gray-07 {
  color: var(--Gray-07, #495057);
}

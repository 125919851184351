@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

body {
    margin: 0;
    font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
        Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic",
        "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.mountAnimation {
    animation: fadeIn 0.2s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform:translateY(-10px);
    }
    100% {
        opacity: 1;
        transform:translateY(0px);
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

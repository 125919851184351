.shadow-header {
    box-shadow: 0px 0px 4px 0px #b49bf5;
}

.dot {
    position: relative;
    padding-left: 12px;
    white-space: pre-line;
}
.dot::before {
    content: "";
    position: absolute;
    top: 9px;
    left: 0;
    width: 3px;
    height: 3px;
    background-color: #343a40;
    border-radius: 100%;
}

.hide-scrollbar {
    scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

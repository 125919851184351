.gradient-purple {
  background: linear-gradient(
    90deg,
    #4c28af 0.82%,
    #8f47ff 39.66%,
    #bf4bff 95.45%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient-sky {
  background: var(
    --Gradient-05,
    linear-gradient(110deg, #5e6eff 0%, #21c1ff 100%)
  );
}

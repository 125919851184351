.list-dot {
    position: relative;
    padding-left: 12px;
    white-space: pre-line;
}
.list-dot::before {
    content: "";
    position: absolute;
    top: 9px;
    left: 0;
    width: 3px;
    height: 3px;
    background-color: #343a40;
    border-radius: 100%;
}
.none .list-dot {
    padding: 0;
}
.none .list-dot::before {
    display: none;
}
.membership .indicator {
    background-color: transparent;
}
.membership .indicator p {
    background-color: transparent;
}
.membership .indicator .step h6 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 13px;
    color: #6032db;
}
.membership .indicator .step h6::before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
}
.membership .indicator .step.two h6::before {
    content: "2";
    border: 1px solid #9f84e9;
    border-radius: 100%;
}
.membership .indicator .step.three h6::before {
    content: "3";
    border: 1px solid #9f84e9;
    border-radius: 100%;
}
.membership .indicator.on .step h6::before {
    content: "";
    background-image: url("../../../public/images/check_on.png");
    background-color: #6032db;
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
    border-radius: 100%;
}
.membership .indicator.com .step h6::before {
    content: "";
    background-image: url("../../../public/images/check.png");
    background-color: #9f84e9;
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: center;
    border-radius: 100%;
}
.detail-dot p:first-child {
    position: relative;
    padding-left: 22px;
}
.detail-dot p:first-child::before {
    content: "";
    position: absolute;
    top: 9px;
    left: 9px;
    width: 3px;
    height: 3px;
    background-color: #495057;
    border-radius: 100%;
}
.announce-new {
    padding-left: 12px;
}
.announce-new::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 4px;
    height: calc(100% - 16px);
    background-color: #6032db;
}
.shadow-modal-box {
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}
.coupon-dot {
    position: relative;
    padding-left: 18px;
}
.coupon-dot::before {
    content: "";
    position: absolute;
    top: 9px;
    left: 8px;
    width: 3px;
    height: 3px;
    background-color: #000;
    border-radius: 100%;
}
.modify-drodown .p-dropdown-label {
    padding: 14px 10px;
    line-height: 22px;
}
.customer-dot p {
    position: relative;
    padding-left: 22px;
}
.customer-dot p::before {
    content: "";
    position: absolute;
    top: 9px;
    left: 9px;
    width: 3px;
    height: 3px;
    background-color: #495057;
    border-radius: 100%;
}
.mypage-on {
    background-color: #efeafb;
}
